body {
  background: transparent;
  font-size: 0.8rem;
}

body.dragging * {
  user-select: none;
}

.App {
  color: white;
  background: var(--background);

  /* --background: rgba(46, 42, 46, 0.03); */
  --border-color: #413d41;
  --border-radius: 0px;
  --border-color-selected: #ff00ff;
  --base-spacing: 4px;
  --dark-background: #2d2d2d;
  --dark-background-highlight: #444444;
}
